import { action } from "mobx";
import { client } from "~/utils/ApiClient";

export type FeedbackSource =
  | "chat-answer"
  | "global-chat-answer"
  | "search"
  | "copilot"
  | "ai-generate-document";

export interface SendFeedbackProps {
  source: FeedbackSource;
  summary?: string;
  liked?: boolean;
  comments?: string;
  referenceId?: string;
  questions?: { question: string; answer: string }[];
}

export interface UpdateFeedbackProps {
  id: string;
  comments: string;
}

export default class FeedbackStore {
  @action
  sendFeedback = async (payload: SendFeedbackProps): Promise<string> => {
    const { liked, source, summary, comments, referenceId, questions } =
      payload;
    const resp = await client.post<{ message: string; data: { id: string } }>(
      "/feedback",
      {
        liked,
        source,
        summary,
        comments,
        referenceId,
        questions,
      }
    );
    return resp.data.id;
  };

  @action
  updateFeedback = async (payload: UpdateFeedbackProps) => {
    const { id, comments } = payload;
    const resp = await client.put<{ message: string }>("/feedback", {
      id,
      comments,
    });
    return resp;
  };
}
